import React from 'react';
import Layout from "../Layout";

export default function Services() {
  return (
    <Layout>
      <div className="shadow-3xl bg-gradient-to-b from-[#3a6c89] to-[#5dabad] py-14 sm:py-24 sm:mt-6 FormReview px-5 sm:px-0">
        <form className="bg-white w-full lg:w-8/12 mx-auto px-8 md:px-0">
          <div className=" lg:w-9/12 mx-auto">
            <h6 className="text-primary font-Montserrat text-2xl font-semibold text-center py-8 sm:py-14">
              Coming Soon...!!!
            </h6>
            <h6 className="text-primary font-Montserrat text-2xl font-semibold text-center py-8 sm:py-14">
              Please stay tuned...
            </h6>
            </div>
        </form>
      </div>
    </Layout>
  )
}
