import React from "react";
import { FooterLink } from "../data/FooterLink";
import { ReactComponent as FooterIcons } from "../assets/images/FooterIcons.svg";
import Logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
// Import icons (you can use icons from libraries like FontAwesome or React Icons)
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa"; // Example for React Icons

export default function Footer() {
  return (
    <>
      <div className="bg-primary sm:py-16 py-10 relative px-5">
        <div className="w-full">
          <div className="flex items-center sm:justify-center mx-auto ">
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-10 sm:gap-20">
              <div className="sm:col-span-2 ">
                <div className=" flex gap-12 items-center justify-center mx-auto ">
                  <img
                    className="mx-auto py-0 w-[65px]"
                    src={Logo}
                    alt="logo"
                  />
                  <h6 className="text-white">
                    Let AI Guide You to <br />
                    the Best College for Your Future
                    <br />
                    Personalized, Fast, and Easy !
                  </h6>
                </div>
              </div>

              <div className="flex-col gap-5 hidden sm:flex">
                {FooterLink.map((item, index) => {
                  return (
                    <Link
                      className="text-white font-light"
                      to={item.link}
                      key={index}
                    >
                      {item.name}
                    </Link>
                  );
                })}
              </div>
              <div>
                <div className="flex flex-col gap-5 ">
                  {/* <p className="text-white font-light">Regd office :</p> */}
                  <p className="text-white font-light">
                    Email : support@collegeintent.com
                  </p>
                  <p className="text-white font-light">
                    Mobile : +91-9665487999
                  </p>
                  {/* <p className="text-white font-light">Privacy Policy </p> */}
                  {/* Social Media Icons */}
                  <div className="flex gap-4">
                    <a
                      href="https://www.facebook.com/Collegeintent/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white hover:text-blue-600"
                    >
                      <FaFacebook size={20} />
                    </a>
                    <a
                      href="https://www.twitter.com/Collegeintent"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white hover:text-blue-400"
                    >
                      <FaTwitter size={20} />
                    </a>
                    {/* <a
                      href="https://www.linkedin.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white hover:text-blue-700"
                    >
                      <FaLinkedin size={20} />
                    </a> */}
                    <a
                      href="https://www.instagram.com/collegeintent/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white hover:text-pink-500"
                    >
                      <FaInstagram size={20} />
                    </a>
                  </div>
                </div>
              </div>
              {/* for mobile */}
              <div className="flex-row flex-wrap justify-between capitalize flex sm:hidden">
                {FooterLink.map((item, index) => {
                  return (
                    <Link
                      className="text-white font-light"
                      to={item.link}
                      key={index}
                    >
                      {item.name}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="bg-white pt-4 sm:pb-14 pb-5 px-5">
        <div className="flex flex-col items-center text-center justify-center mx-auto ">
          <p className="mb-5">
            Designed in India, Crafted for Students Worldwide...
          </p>
          <FooterIcons />
        </div>
      </div> */}
    </>
  );
}