
import React from 'react'

export default function SliderBox({ item, index }) {

    return (
        <div key={index} className='border-4 my-5 w-10/12 lg:min-w-[580px] lg:h-[450px] xl-min-w-[100%] mx-auto sm:max-h-[480px]   border-secondary rounded-[20px] sm:rounded-[70px] overflow-hidden'>
            <div className=" grid grid-cols-1 sm:grid-cols-2 gap-4 h-full">
                <div className='bg-secondary order-last sm:order-first h-full p-7 flex justify-center items-start flex-col'>
                    <h6 className='text-white font-Montserrat text-xl font-semibold'>{item.title}</h6>
                    <p className='text-textSecondary font-Montserrat font-normal my-6'>
                        {item.label}
                    </p>
                    <button className='bg-white shadow-xl text-[#4a2e8e] py-2 text-[9px] font-semibold px-4 rounded-full uppercase'>
                        more info
                    </button>
                </div>
                <div className='flex justify-center items-center'>
                    <img
                        src={item.img}
                        alt={item.title}
                        className='text-center  mx-auto p-5'
                    />
                </div>
            </div>
        </div>
    )
}

