import React from 'react'
import Logo from '../assets/images/logo.png'
export default function TopLogo() {
    return (
        <div className="lg:w-10/12 w-full mx-auto">
            <div className=" bg-primary rounded-b-[60px]" style={{ boxShadow: ' rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px' }}>
                <img src={Logo} className='mx-auto py-6' width={85} alt='logo'/>
            </div>
        </div>
    )
}
