 
import oneImg from '../assets/images/PlatformSlider/one.svg'
import SecondImg from '../assets/images/PlatformSlider/Second.svg'
import ThirdImg from '../assets/images/PlatformSlider/Third.svg'
import FourImg from '../assets/images/PlatformSlider/Four.svg'

export const SliderData = [
    {
        title: 'Exams',
        label: `Streamline your exam preparation with 
AI-powered planning. 
Our platform creates 
personalized study schedules, 
tracks progress, and optimizes
your study time for 
better results.`,
        img: SecondImg,

    },
    {
        title: 'Colleges',
        label: `Our platform analyzes
your preferences, goals, and
academic profile to 
recommend the best colleges
for you - saving time and
making smarter decisions
easier.`,
        img: ThirdImg,
    },
    {
        title: `Real Students. 
Real Reviews. 
Find Your College Fit.`,
        label: `Get authentic insights from
real students about colleges
across the country. 
Our platform provides 
honest reviews to help you 
make informed decisions.`,
        img: oneImg,
    },
    {
        title: 'Tools',
        label: `Our advanced tools analyze
your preferences, academics, 
and goals to provide 
personalized college 
recommendations, making 
the selection process simple
and efficient.`,
        img: FourImg,
    }
]