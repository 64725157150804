
import React from 'react'
import HeroImg from '../../assets/images/HeroImgs.jpg' 
import { FaMicrophoneAlt } from "react-icons/fa"; 

export default function Hero() {
    return (
        <div className="w-full flex justify-center items-center sm:mt-6 relative"> 
            <img
                src={HeroImg}
                alt="Hero Images"
                className='w-full h-full sm:h-[100vh] absolute top-0 right-0 left-0 bottom-0'
            />
            <div className='z-50 flex flex-col justify-center items-center  sm:h-[100vh] h-full py-28 sm:py-0 px-5'>
                <h4 className='text-white font-Montserrat text-center font-semibold text-2xl'>Discover Your Ideal College Match with Al-Powered Precision!</h4>

                <form className=" sm:w-[400px] w-full mx-auto mt-8">

                    <div className="relative">
                        <div className="bg-[#59c4d6] absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none rounded-l px-4">
                            <svg className="w-6 h-6 rotate-90  text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                        <input disabled={true} defaultValue="Coming Soon...!!!" type="search" id="default-search" className="block w-full p-4 bg-searchBg ps-14 text-sm text-gray-900 border border-gray-300 rounded-lg dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  " />
                        <div className=" absolute top-3  end-3 bottom-0 ">
                            <FaMicrophoneAlt style={{ color: '#00bbbd', fontSize: '30px' }} />
                        </div>

                        {/* <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button> */}
                    </div>
                </form>

            </div>


        </div>
    )
}
