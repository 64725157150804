import React from 'react'
import SliderBox from './SliderBox'


import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
 
import 'swiper/css/scrollbar';

import {  Navigation } from 'swiper/modules'


import { SliderData } from '../../data/SliderData';

export default function Platform() {

    return (
        <>
            <div className='platFormPgae w-[100%] mx-auto bg-white '>

                <div className='text-center w-full lg:w-8/12 mx-auto mt-14 sx:mt-20 mb-10 px-5 sm:px:0 text-center'>
                    <h4 className='  text-primary font-Montserrat font-semibold text-2xl'>Find Your Perfect College with Intent Al !!</h4>
                    <p className='text-[18px] text-primary font-Montserrat font-normal mt-4 sm:px-7 text-justify sm:text-center '>
                        Our platform leverages advanced artificial intelligence to match students with colleges that align with their goals, preferences, and strengths. Explore tailored recommendations, compare options, and make informed decisions with ease. <br /> Simplify your college search today!
                    </p>
                </div>
                <Swiper
                    slidesPerView={1}
                    centeredSlides={true}
                    loop={true}
                    spaceBetween={120}
                    grabCursor={true}
                    navigation={true}
                    breakpoints={{
                        1024: {
                            slidesPerView: 2, 
                           
                        },
                        768: {
                            slidesPerView: 1,
                        },
                    }}

                    modules={[Navigation]}
                    className="mySwiper w-full"
                >

                    {
                        SliderData.map((item, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <SliderBox item={item} index={index} />
                                </SwiperSlide>)
                        })
                    }


                </Swiper>

            </div>
        </>
    )
}

