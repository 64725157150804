export const FooterLink = [
    {
        id: 1,
        name: "Home",
        link: "/"
    },
    {
        id: 2,
        name: "About Us",
        link: "/about-us"
    },
    {
        id: 3,
        name: "Services",
        link: "/services"
    },
    {
        id: 4,
        name: "Contact Us",
        link: "/contact-us"
    },
    // {
    //     id: 5,
    //     name: "FAQs",
    // },

];