import React from 'react';
import Layout from "../Layout";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";

export default function ContactUs() {
  return (
    <Layout>
      <div className="shadow-3xl bg-gradient-to-b from-[#3a6c89] to-[#5dabad] py-14 sm:py-24 sm:mt-6 FormReview px-5 sm:px-0">
        <form className="bg-white w-full lg:w-8/12 mx-auto px-8 md:px-0">
          <div className=" lg:w-9/12 mx-auto">
            <h6 className="text-primary font-Montserrat text-2xl font-semibold text-center py-8 sm:py-14">
              Contact Us
            </h6>
            <section>
                <p>
                  Email us at: <strong>support@collegeintent.com</strong>
                </p>
              <br/>
                <p>
                  Mobile Number: <strong>+91-9665487999</strong>
                </p>
              <br/>
            </section>
            <div className="flex gap-4">
              <a
                href="https://www.facebook.com/Collegeintent/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue hover:text-blue-600"
              >
                <FaFacebook size={20} />
              </a>
              <a
                href="https://www.twitter.com/Collegeintent"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue hover:text-blue-400"
              >
                <FaTwitter size={20} />
              </a>
              {/* <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-blue-700"
              >
                <FaLinkedin size={20} />
              </a> */}
              <a
                href="https://www.instagram.com/collegeintent/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-pink hover:text-pink-500"
              >
                <FaInstagram size={20} />
              </a>
            </div>
            <br/>
          </div>
        </form>
      </div>
    </Layout>
  )
}
