import React from 'react';
import Layout from "../Layout";

export default function AboutUs() {
  return (
    <Layout>
      <div className="shadow-3xl bg-gradient-to-b from-[#3a6c89] to-[#5dabad] py-14 sm:py-24 sm:mt-6 FormReview px-5 sm:px-0">
        <form className="bg-white w-full lg:w-8/12 mx-auto px-8 md:px-0">
          <div className=" lg:w-9/12 mx-auto">
            <h6 className="text-primary font-Montserrat text-2xl font-semibold text-center py-8 sm:py-14">
              About Us
            </h6>
          <section>
            <p>
                At <strong>CollegeIntent</strong>, we are committed to revolutionizing the engineering college admission process for students across India to find their dream college. With over <strong>10 years</strong> of expertise in academic guidance and counseling, we understand the challenges students face when selecting the right college and course from India’s vast higher education landscape.
            </p>
            <br/>
            <p>
                In a competitive environment where <strong>over 1.5 million</strong> students annually apply to engineering colleges, students are often overwhelmed by the complexity of entrance exams, cut-offs, and the sheer volume of information. From navigating <strong>NEET, JEE, and state-level exams</strong> to understanding the nuances of <strong>college rankings, seat allotments, and fee structures</strong>, the process can be daunting. Mistakes in choice filling, a lack of awareness about emerging colleges, or missing out on opportunities due to incorrect cut-off analysis are just a few hurdles students face.
            </p>
            <br/>
            <p>
                At <strong>CollegeIntent</strong>, we tackle these challenges head-on. We offer a <strong>data-driven, student-first approach</strong> that combines expert counseling with innovative tools like personalized college matching, <strong>predictive cut-off analysis</strong>, and in-depth reviews of lesser-known yet highly-rated institutions. Our mission is to ensure that students are not only aware of top-tier options like <strong>IITs</strong> and <strong>NITs</strong>, but also discover hidden gems in engineering education across India that fit their preferences and academic goals.
            </p>
            <br/>
            <p>
                With over <strong>2,000</strong> colleges, <strong>30,000+ programs</strong>, and comprehensive admission data from <strong>across 50+ universities</strong>, we empower students to make informed decisions. Our goal is to guide students towards their dream colleges with confidence, reducing the stress of the admission process and helping them secure their future in a highly competitive field.
            </p>
            <br/>
            <p>
                At CollegeIntent, we are dedicated to transforming your college search into a seamless and informed journey, ensuring you find the best match for your aspirations and academic ambitions.
            </p>
            <br/>
            <br/>
          </section>
          </div>
        </form>
      </div>
    </Layout>
  )
}
