 
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; 
 import './App.css';
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import ContactUs from "./pages/ContactUs";
import FormReview from "./pages/FormReview";
import ScratchPage from "./pages/ScratchPage";

const App = () => {
  return (
    <Router>
     
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact-us" element={<ContactUs />} />
        {/* <Route path="/form_review" element={<FormReview />} />
        <Route path="/form_review" element={<FormReview />} />
        <Route path="/scratch" element={<ScratchPage />} /> */}
      </Routes>
      
    </Router>
  );
};

export default App;
