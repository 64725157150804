
import { useState } from "react";
import { IoMenu } from "react-icons/io5";
import { Link } from "react-router-dom";
import Logo from '../assets/images/logo.png'
import { IoIosCloseCircleOutline } from "react-icons/io";



export default function Header() {
  const [toggleMenu, setToggleMenu] = useState(false);

  const links = [
    {
      id: 1,
      name: "home",
      links: '/'
    },
    {
      id: 2,
      name: "about us",
      links: '/about-us'
    },
    {
      id: 3,
      name: "services",
      links: '/services'
    },
    {
      id: 4,
      name: "contact us",
      links: '/contact-us'
    },

  ];
  return (
    <nav className="flex sm:px-5 sm:justify-center justify-end relative items-center lg:w-7/12 w-full sm:mt-20 mt-6 mx-auto lg:px-0  ">

      {/* Desktop menu  */}

      <ul className="hidden sm:flex justify-between w-full items-center ">
        {
          links.map((item, index) => {
            return (
              <Link key={index} to={`${item.links}`}>
                <li className='flex items-center font-Montserrat text-base uppercase font-medium text-secondary'>
                  {item.name}
                </li>
              </Link>
            )
          })
        }
      </ul>

      {/* mobile menu  */}
      <div className="ms-16 pe-5" >
        <IoMenu style={{ color: '#00bbbd', fontSize: '40px' }} onClick={() => setToggleMenu(!toggleMenu)} />
      </div>

      <div
        className={`fixed z-[99999] top-0 w-full  bg-gray-100 overflow-hidden flex flex-col lg:hidden gap-12  origin-top duration-700 ${!toggleMenu ? "h-0" : "h-full"
          }`}
      >
        <div className="flex bg-primary justify-between items-center py-4 px-8">
          <img src={Logo} className='' width={55} alt='logo' />
          <div onClick={() => setToggleMenu(false)}>
            <IoIosCloseCircleOutline className="text-white text-3xl" />
          </div>
        </div>
        <div className="flex flex-col gap-8 font-bold tracking-wider  px-8 py-10">
          <ul className=" flex flex-col gap-10 justify-between w-full items-start ">
            {
              links.map((item, index) => {
                return (
                  <Link key={index} to={`${item.links}`}>
                    <li className='flex items-center font-Montserrat text-base uppercase font-medium text-secondary'>
                      {item.name}
                    </li>
                  </Link>
                )
              })
            }
          </ul>
        </div>
      </div>


    </nav>
  )
}