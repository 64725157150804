import React from 'react'

import { ReactComponent as StudySvg } from '../../assets/images/study.svg';


export default function Recommendations() {
    return (
        <div className='w-full mx-auto bg-[#f0f1f1] py-10 pb-10 mt-16 px-5'>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-14">
                <div className='w-full md:w-[360px] md:ms-auto h-full text-end'>
                    <StudySvg className='w-full' />
                </div>
                <div className='text-center md:text-start flex items-center md:items-start flex-col justify-center'>
                    <h5 className='hidden text-2xl font-bold text-textSecondary w-[240px]'>Find Your
                        Perfect College
                        Faster with <br />
                        AI-Driven
                        Recommendations!</h5>

                        <h5 className='text-2xl font-bold text-textSecondary md:w-[240px]'>Find Your
                        Perfect College
                        Faster with  
                        AI-Driven
                        Recommendations!</h5>
                    <button className='bg-white shadow-2xl text-[#4a2e8e] py-2 mt-4 text-[9px] font-semibold px-4 rounded-full uppercase'>
                        more info
                    </button>
                </div>

            </div>
        </div>
    )
}

