import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import TopLogo from './components/TopLogo'

export default function Layout({ children }) {
    return (
        <>
            <TopLogo />
            <Header />
            {children}
         <Footer /> 
        </>
    )
}
