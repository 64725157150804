import React from 'react'
import Layout from '../Layout'
import Hero from '../components/Home/Hero.js'
import Subscribe from '../components/Subscribe.js'
import Recommendations from '../components/Home/Recommendations.js'
import Platform from '../components/Home/Platform.js'
import Testimonials from '../components/Testimonials.js'

export default function Home() {
  return (
    <Layout>
      <Hero />
      <Platform />
      {/* <Testimonials /> */}
      <Recommendations />
      {/* <Subscribe /> */}
      
    </Layout>
  )
}
